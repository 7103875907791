import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { orderRoutes } from '@/modules/orders/routes.ts';
// Loading views without lazy
import Init from '@/Init.vue';
import NotFound from '@/NotFound.vue';
import { RouteName } from '../common/routes/routeName';

export const routes: RouteRecordRaw[] = [
  {
    path: '/:integration/:storeId',
    component: Init,
    name: RouteName.STORE,
    children: [
      {
        path: '',
        name: RouteName.WELCOME,
        component: () => import('@/modules/welcome/WelcomeView.vue'),
      },
      {
        path: 'standby',
        name: RouteName.STAND_BY_BANNER,
        component: () => import('@/modules/welcome/views/StandbyBannerView.vue'),
      },
      {
        path: 'order',
        name: RouteName.ORDERS,
        children: [...orderRoutes],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: RouteName.NOT_FOUND,
    component: NotFound,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
