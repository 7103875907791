import { RouteRecordRaw } from 'vue-router';
// Loading views without lazy
import OrdersWrapper from './OrdersWrapper.vue';
import OrderOptionsWrapper from '@/modules/orders/OrderOptionsWrapper.vue';
import { RouteName } from '../../common/routes/routeName';

export const orderRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteName.WRAPPER,
    component: OrdersWrapper,
    redirect: { name: RouteName.ORDER_OPTIONS },
    children: [
      {
        path: 'options',
        name: RouteName.ORDER_OPTIONS,
        component: OrderOptionsWrapper,
        redirect: { name: 'OrderTypeView' },
        children: [
          {
            path: 'type',
            name: RouteName.ORDER_TYPE,
            component: () => import('@/modules/order-type/views/OrderTypeView.vue'),
          },
          {
            path: 'table',
            name: RouteName.ORDER_TABLE,
            component: () => import('@/modules/order-type/views/OrderTableView.vue'),
          },
          {
            path: 'categories',
            name: RouteName.ORDER_CATEGORIES,
            component: () => import('@/modules/orders/views/OrderCategoriesView.vue'),
          },
        ],
      },
      {
        path: 'products',
        name: RouteName.PRODUCTS,
        component: () => import('@/modules/orders/views/OrderProductsView.vue'),
      },

      {
        path: 'customize',
        name: RouteName.DROPDOWN_CUSTOMIZE,
        component: () => import('@/modules/customize/views/DropdownCustomizeItem.vue'),
      },
      {
        path: 'customize',
        name: RouteName.STEP_CUSTOMIZE,
        component: () => import('@/modules/customize/views/StepCustomizeItem.vue'),
      },
      {
        path: 'cart',
        name: RouteName.CART,
        component: () => import('@/modules/cart/views/CartView.vue'),
      },
      {
        path: 'checkout',
        name: RouteName.CHECKOUT,
        component: () => import('@/modules/checkout/views/CheckoutView.vue'),
      },
    ],
  },
];
